body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
  background-color: #000000;
  color: rgb(240, 240, 240);
}

.ui.grid > .row h3.header {
  margin-bottom: 0;
  margin-top: 40px;
  color:rgb(240, 240, 240);
}

.ui.grid > .row .ui.item.menu {
  border: none;
}

.ui.grid > .row .ui.item.menu .item {
  background-image: -webkit-linear-gradient(top, #797979 0, #464646 100%);
  background-image: linear-gradient(to bottom, #797979 0, #464646 100%);
  border: 1px solid #d5d5d5;
  border-left-color: rgb(240, 240, 240);
  color: white;
  font-size: 18px;
}

.ui.grid > .row .ui.item.menu .item.active {
  border-left: 0px none #ffffff;
  box-shadow: inset 2px 4px 7px rgba(58, 58, 58, 0.187);
}

.ui.grid .row .ui.center.aligned.container h1 {
  font-size: 63px;
}

.ui.grid .row .ui.center.aligned.container p {
  font-size: 20px;
  line-height: 1.5;
}

.ui.grid .three.column.row h1 {
  font-size: 24px;
}

footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  font-size: 16px;
}

img.ui.image {
  margin-bottom: 20px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

/* div.ui.segment.image-segment {
  background: #eeeeee;
} */

div.ui.container.contact-form{
  /* text-align: center; */
  margin-left: auto;
  margin-right: auto;
  color: rgb(240, 240, 240);}

div.column > h1.header{
  color: rgb(240, 240, 240);}

div.column > p{
  font-size: 18px;
}

div.welcome > h2.header{
  color: rgb(240, 240, 240);}

div.welcome > p{
  font-size:18px;
}

div.contact-form > h2.header{
  color: rgb(240, 240, 240);}


div.ui.container > h2.header{
  color: rgb(240, 240, 240);}


div.ui.container > p{
  color: rgb(240, 240, 240);  font-size: 18px;
}

ul.list > li{
  font-size:18px;
  line-height: 2;
}

form.ui.form .field > label.form-label{
  color: rgb(240, 240, 240);  /* border: 1px solid green; */
  font-size: 18px;
}

div.ui.checkbox > label{
  color: rgb(240, 240, 240);  /* font-size: 16px; */

}

h2.ui.header{
  font-size: 24px;
}

h1.ui.huge.header{
  font-size: 24px;
}

h3.ui.header{
  color: rgb(240, 240, 240);
}
